import { Controller } from 'stimulus';
import Notifications from '../mixins/noty';

export default class extends Controller {
  initialize() {
    this.link = this.element.getAttribute('data-link');
    this.message = this.element.getAttribute('data-message');
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.link);
    Notifications.methods.showInformation(this.message);
  }
}
