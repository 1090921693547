import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['defaultview', 'saveview'];

  toggle() {
    this.defaultviewTarget.classList.toggle('u-hidden');
    this.saveviewTarget.classList.toggle('u-hidden');
  }
}
